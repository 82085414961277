<template>
  <div class="ele-body">
    <a-card :bordered="false">
      <a-form
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col>
            <a-form-item class="ant-descriptions-title big"
              >基本信息</a-form-item
            >
          </a-col>
        </a-row>
        <!-- <a-divider /> -->
        <a-form-item
          label="商品名称:"
          :labelCol="{ span: 2, offset: 0 }"
          name="goodsName"
        >
          <a-input
            v-model:value="form.goodsName"
            placeholder="请输入商品名称"
            allow-clear
          />
        </a-form-item>
        <a-form-item
          label="商品卖点:"
          :labelCol="{ span: 2, offset: 0 }"
          name="sellingPoint"
        >
          <a-input
            v-model:value="form.sellingPoint"
            placeholder="请输入商品卖点"
            allow-clear
          />
        </a-form-item>
        <a-form-item
          label="商品分类:"
          :labelCol="{ span: 2, offset: 0 }"
          name="categoryId"
        >
          <a-select
            allow-clear
            placeholder="请选择商品分类"
            v-model:value="form.categoryId"
          >
            <a-select-option
              v-for="item in sortList"
              :key="item.categoryId"
              :value="item.categoryId"
            >
              {{ item.categoryName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="商品图片:"
          :labelCol="{ span: 2, offset: 0 }"
          name="goodsPics"
        >
          <a-upload
            v-model:file-list="fileList"
            list-type="picture-card"
            :remove="removeFile"
            :customRequest="doUpload"
            @preview="handlePreview"
          >
            <div v-if="fileList.length < 8">
              <plus-outlined />
              <div class="ant-upload-text">上传商品图片</div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            :bodyStyle="{
              textAlign: 'center',
              paddingTop: '40px',
              width: '100%',
              overflow: 'auto'
            }"
            @cancel="previewVisible = false"
          >
            <img alt="example" :src="previewImage" />
          </a-modal>
          <div>
            <a-radio-group
              name="radioGroup"
              v-model:value="radioValue"
              :default-value="radioValue"
              @change="radioChange"
            >
              <a-radio
                :style="radioStyle"
                :value="index"
                v-for="(item, index) in this.fileList.length"
                :key="index"
              ></a-radio>
            </a-radio-group>
          </div>
          <div style="color: #999999">
            注：主图片默认为第一张，单选可自主选择主图片，最多可上传8张！图片比例最好为750*750！
          </div>
        </a-form-item>
        <a-row>
          <a-col>
            <a-form-item class="ant-descriptions-title big"
              >规格/库存</a-form-item
            >
          </a-col>
        </a-row>
        <!-- <a-divider /> -->
        <a-form-item
          label="商品积分:"
          :labelCol="{ span: 2, offset: 0 }"
          name="goodsPrice"
        >
          <a-input
            v-model:value="form.goodsPrice"
            placeholder="请输入商品积分"
            allow-clear
          />
        </a-form-item>
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item
              label="商品数量:"
              :labelCol="{ span: 8, offset: 0 }"
              name="stockNumber"
            >
              <a-input
                v-model:value="form.stockNumber"
                placeholder="请输入商品数量"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item
              label="状态:"
              :labelCol="{ span: 8, offset: 0 }"
              name="goodsStatus"
            >
              <a-select
                allow-clear
                placeholder="状态查询"
                v-model:value="form.goodsStatus"
              >
                <a-select-option
                  v-for="item in stateDict"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          label="内容"
          :labelCol="{ span: 2, offset: 0 }"
          name="goodsContent"
        >
          <tinymce-editor v-model:value="content" :init="{ height: 525 }" />
        </a-form-item>
        <a-form-item
          label=" "
          :colon="false"
          :labelCol="{ span: 2, offset: 0 }"
          name="virtualViews"
        >
          <a-button type="primary" @click="save"> 提交 </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor'
import { Modal } from 'ant-design-vue'
import * as pointsGoodsApi from '@/api/jyyw/pointsGoods'
import * as pointsCategoryApi from '@/api/jyyw/pointsCategory'
import * as dictDataApi from '@/api/sys/dictData'
import { PlusOutlined } from '@ant-design/icons-vue'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'productEdit',
  components: {
    TinymceEditor,
    PlusOutlined
  },
  data() {
    return {
      value: '',
      form: {},
      rules: [],
      fileList: [],
      previewImage: '',
      loading2: false,
      previewVisible: false,
      goodsPics: '',
      isUpdate: false,
      content: '',
      onshow: true,
      // 商品名称list
      sortList: [],
      // 状态字典项
      stateDict: [],
      radioValue: 0,
      radioStyle: {
        width: '112px',
        textAlign: 'center',
        marginRight: '0px',
        paddingBottom: '10px'
      }
    }
  },
  created() {
    this.querysortList()
    this.queryArticle()
    this.queryDataStates()
  },
  watch: {
    $route(to, from) {
      if (to.name === '/jyyw/points/productEdit') {
        this.onshow = true
        this.content = this.form.goodsContent
        if (this.$route.query.goodsId) {
          this.queryArticle()
          this.isUpdate = true
        } else {
          this.form = {}
          this.content = ''
          this.isUpdate = false
        }
      } else {
        if (this.onshow) {
          this.onshow = false
          this.form.goodsContent = this.content
          this.content = ''
        }
      }
    }
  },
  methods: {
    /* 上传 */
    doUpload(data) {
      const formData = new FormData()
      formData.append('file', data.file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post('/file/upload', formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            // this.goodsPics = res.data.location
            this.fileList[this.fileList.length - 1] = {
              ...this.fileList[this.fileList.length - 1],
              status: 'done',
              url: res.data.location
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    removeFile(file) {
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            if (this.fileList.length > 0) {
              const urls = this.fileList.map((item) => {
                return item.url
              })
              if (urls.length > 0) {
                this.form.goodsPics = urls.join(',')
              }
            } else {
              this.form.goodsPics = ''
            }
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    save() {
      this.saveProcess()
    },
    saveProcess() {
      const that = this
      const hide = that.$message.loading('提交中..', 0)
      that.$refs.form
        .validate()
        .then(() => {
          that.loading = true
          if (that.fileList.length > 0) {
            that.form.goodsMainPic = that.fileList[that.radioValue].url
            const urls = that.fileList.map((item) => {
              return item.url
            })
            if (urls.length > 0) {
              that.form.goodsPics = urls.join(',')
            }
          } else {
            that.form.goodsMainPic = ''
            that.form.goodsPics = ''
          }
          that.form.goodsContent = that.content
          pointsGoodsApi
            .save(that.form, that.isUpdate)
            .then((res) => {
              hide()
              that.loading = false
              if (res.code === 0) {
                Modal.confirm({
                  title: '提示信息',
                  content: '您已修改，是否返回上一页？',
                  okText: '确认',
                  cancelText: '取消',
                  onOk() {
                    that.$message.success(res.msg)
                    that.form.goodsContent = res.data
                    that.$router.push({
                      path: './product'
                    })
                  }
                })
              } else {
                that.$message.error(res.msg)
              }
            })
            .catch((e) => {
              that.loading = false
              that.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 获取编辑器纯文本内容 */
    showText() {
      Modal.info({
        maskClosable: true,
        content: this.$util.htmlToText(this.value)
      })
    },
    queryArticle() {
      const query = this.$route.query
      if (query.goodsId > 0) {
        const that = this
        that.isUpdate = true
        const hide = that.$message.loading('请求中..', 0)
        pointsGoodsApi
          .getById(query.goodsId)
          .then((res) => {
            hide()
            if (res.code === 0) {
              that.form = Object.assign({}, that.data, res.data)
              that.content = that.form.goodsContent
              that.fileList = []

              if (that.form.goodsPics) {
                const goodsPicsUrl = that.form.goodsPics.split(',')
                goodsPicsUrl.map((item, index) => {
                  console.log('edit-goodsPicsUrl', item)
                  if (item === that.form.goodsMainPic) {
                    that.radioValue = index
                  }
                  that.fileList.push({
                    name: 'image.png',
                    status: 'done',
                    url: item,
                    uid: index
                  })
                })
              }
            } else {
              that.$message.error(res.msg)
            }
          })
          .catch((e) => {
            hide()
            that.$message.error(e.message)
          })
      }
    },
    // 获取商品名称List
    querysortList() {
      pointsCategoryApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.sortList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 状态字典项
    queryDataStates() {
      dictDataApi
        .querydictdata('shelfs')
        .then((res) => {
          if (res.code === 0) {
            this.stateDict = res.data
            this.stateDict.map((item) => {
              item.dictDataCode = parseInt(item.dictDataCode)
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    radioChange(e) {
      this.radioValue = e.target.value
      // console.log('单选', this.radioValue)
    }
  }
}
</script>

<style scoped>
.span {
  color: grey;
  padding-left: 10px;
}
.big {
  font-size: 20px;
}
.radio {
  width: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
}
</style>
